@import-normalize;

html,
body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #3b3d45;
}

p {
  margin: 0;
}

input,
button,
textarea {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

button {
  font-size: 14px;
  letter-spacing: 1.5px;
  font-family: 'Inter', sans-serif;
}

/** utility classes **/

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.flex-1 {
  flex: 1;
}
